/* eslint-disable react/no-danger */
import React from 'react';
import { shape } from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from 'components/Layout';
import Seo from 'components/Seo';

const About = ({ data }) => {
  const post = data.markdownRemark;
  return (
    <Layout>
      <Seo title={post.frontmatter.title} description={post.excerpt} />
      <Main>
        <h1>About Us</h1>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </Main>
    </Layout>
  );
};

About.propTypes = {
  data: shape({}).isRequired,
};

const Main = styled.div`
  margin: 2rem 0;
`;

export default About;

export const pageQuery = graphql`
  query {
    markdownRemark(fields: { slug: { eq: "/about/" } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
      }
    }
  }
`;
/* eslint-enable react/no-danger */
